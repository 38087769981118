import loadable from '../../utilities/loadable'

export const Marketplace = loadable(() => import('./Marketplace'))
export const ProductCondition = loadable(() =>
  import('./ProductDetails/ProductCondition')
)
export const ProductDetails = loadable(() =>
  import('./ProductDetails/ProductDetails')
)
export const ProofOfPurchase = loadable(() =>
  import('./ProductDetails/ProofOfPurchase')
)

export const PurchaseHistory = loadable(() =>
  import('./ProductDetails/PurchaseHistory')
)

export const NewRequestModal = loadable(() =>
  import('./ProductRequest/NewRequestModal')
)

export const UgoModal = loadable(() => import('./UgoModal/UgoModal'))

export const SupplierProductDownloads = loadable(() =>
  import('./ProductDetails/SupplierProductDownloads')
)

export const CarouselAdvertisement = loadable(() =>
  import('./ProductDetails/CarouselAdvertisement')
)
